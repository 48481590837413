import React, { useState, useContext } from "react";
import "./style.css";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import image from "../../assets/sociallocket.png";
import { Link } from "react-router-dom";

import { PreSaleContext } from "../../Context/presaleContext";

const Header = () => {
	const { connectWallet, currentAccount } = useContext(PreSaleContext);
	const [open, setOpen] = useState(false);
	return (
		<header className="header_container">
			<nav className="navigation">
				<Link to={"/"}>
					<img src={image} alt="logo" height={80} width={110} />
					{/* <h1>SocialLocket Marketplace</h1> */}
				</Link>
			</nav>

			<div className="right_container">
				<ul>
					<li>
						<Link to="/" shallow>
							<h2>Home</h2>
						</Link>
					</li>
					<li>
						<Link to="/packages" shallow>
							<h2>Packages</h2>
						</Link>
					</li>
				</ul>
				<div className="sub_right_container">
					{currentAccount === "" ? (
						<button onClick={connectWallet}>connect wallet</button>
					) : (
						<button onClick={connectWallet}>
							{currentAccount.substring(0, 5) +
								"..." +
								currentAccount.substring(38, 42)}
						</button>
					)}

					<div
						onClick={() => {
							setOpen(!open);
						}}
					>
						<span></span>
						<span></span>
						<span></span>
						{open && (
							<ul>
								{/* <li className="text">
									<BsDiscord />
									<a href="https://discord.com/channels/1109444072332087316/1109444072776663121"></a>
									<span>Discord</span>
								</li>
								<li className="text">
									<BsTwitter />
									<a href="https://twitter.com/social_locket"></a>
									<span>Twitter</span>
								</li> */}
								<li className="text">
									<BsDiscord />
									<a href="https://discord.com/channels/1109444072332087316/1109444072776663121">
									<span>Discord</span>
									</a>
                        		</li>
								<li className="text">
									<BsTwitter />
									<a href="https://twitter.com/social_locket">
									<span>Twitter</span>
									</a>
                        		</li>
							</ul>

						)}
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
