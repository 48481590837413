import presale from "./presaleABI.json";

import erc20 from "./erc20.json";

export const presaleContractPoly = "0x7ECb502Db3b2D3eaFD4256307D52Cf5E15f22e36";

export const presaleContractBsc = "0x5CE345bdBc497694C948A0F10e41A6c839f9A4eE";
// 6 decimals
export const usdtContractPoly = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
// 18 decimals
export const usdtContractBsc = "0x206CDCdC05036416D5dadB0f4CA215f4E90DF85F";

export const presaleABI = presale.abi;

export const erc20ABI = erc20;
export const polyRPC = "https://rpc-mainnet.maticvigil.com";
export const bscRPC = "https://bsc-dataseed.binance.org/";
// export const polyRPC = "https://eth-sepolia.g.alchemy.com/v2/Ne2TEobtLoImEvWq7oJXrjzlf3ZLOC69";
// export const bscRPC = "https://eth-sepolia.g.alchemy.com/v2/Ne2TEobtLoImEvWq7oJXrjzlf3ZLOC69";
