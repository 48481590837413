import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/Header";
import Layout from "./Components/Layout/Layout";
import Home from "./pages/Home/Home";
import Packages from "./pages/Packages/Packages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

	return (
		<>
			<Header />
			<ToastContainer />
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="/" element={<Home />} />
					<Route path="/packages" element={<Packages />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
