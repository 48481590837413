import React, { useContext } from "react";
import "./index.css";
import Card from "../../Components/Card/Card";
import { PreSaleContext } from "../../Context/presaleContext";
// import currency from "../../assets/currency1.jpg"
import currency from "../../assets/sociallocket.png";
const Packages = () => {
	const { tokenBalance } = useContext(PreSaleContext);
	return (
		<>
			<section className="max-w-screen-lg w-full m-auto mb-[3.5rem] md:mb-0 ">
				<div className="my-4 grid md:grid-cols-2 grid-cols-1 gap-x-[12px] gap-y-[12px]">
					<div className="balance_card_container left">
						<div className="left">
							<h2>my balance</h2>
							<div className="left_bottom">
								<img src={currency} alt="currency" />
								<span>{tokenBalance}</span>
							</div>
						</div>
						<div className="right">
							<h2>SLT Listing Price</h2>
							<div className="right_bottom">
								<span>$0.80</span>
							</div>
						</div>
					</div>
					<div className="balance_card_container right">
						<span>
							You will earn 10% in USDT from all purchases of your referrals.
						</span>
					</div>

					<div className="balance_card_container right">
						<span>
							All Purchases over $2000 receive 15% rewards
						</span>
					</div>
					<div className="balance_card_container right">
						<span>
							All Purchases over $5000 receive 25% rewards
						</span>
					</div>
				</div>
				<div className="py-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-x-[12px] gap-y-[12px] ">
					<Card amount={500} />
					<Card amount={1000} />
					<Card amount={2000} />

					<Card amount={5000} />
					<Card amount={10000} />
					<Card amount={20000} />

					<Card amount={50000} newClass={`lg:col-span-2 col-span-1`} />
				</div>
			</section>
		</>
	);
};
export default Packages;
