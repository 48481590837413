import React, { useState, useContext } from "react";
import "./style.css";

import { PreSaleContext } from "../../Context/presaleContext";
// import currency from "../../assets/currencyLogo.jpg"
import currency from "../../assets/currency1.jpg";

function Card({ amount, newClass }) {
	const { connectWallet, currentAccount, price, buyTokenWithToken } =
		useContext(PreSaleContext);
	const handleBuy = () => {
		const token = amount / price;
		buyTokenWithToken(amount, token).finally(() => {
			setIsLoadingContracts(false);
		});
	};
	const [isLoadingContracts, setIsLoadingContracts] = useState(false);
	return (
		<div className={`card_container ${newClass}`}>
			<div className="top">
				<p>
					{/* <GiReactor style={{ fontSize: "2.5rem" }} /> */}
					<span>{amount / price}</span>
				</p>
				<div className="currency_container">
					<img src={currency} alt="currency" />
					<p>SLT</p>
				</div>
			</div>

			{isLoadingContracts ? (
				<div className="flex justify-center mt-4">
					<div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 custom-border"></div>
				</div>
			) : (
				<div className="mt-4">
					{currentAccount ? (
						<button
							className="btn"
							onClick={() => {
								setIsLoadingContracts(true);
								handleBuy();
							}}
						>
							${amount} Buy
						</button>
					) : (
						<button className="btn" onClick={connectWallet}>
						${amount} Buy
						</button>
					)}
				</div>
			)}
		</div>
	);
}

export default Card;
