import "./style.css";
import React, { useEffect, useState, useContext } from "react";
import { PreSaleContext } from "../../Context/presaleContext";
import Packages from "../Packages/Packages";
import { toast } from "react-toastify";
import PromotionTimer from "../../Components/PromotionTimer";

export default function Home() {
	const {
		connectWallet,
		currentAccount,
		chainId,
		stage,
		price,
		nextPrice,
		NativeToTokenHelper,
		buyTokenWithNative,
		buyTokenWithToken,
		tokenSold,
		tokeninstage,
	} = useContext(PreSaleContext);
	const [currency, setCurrency] = useState("Matic");
	const [tokens, setTokens] = useState("");
	const [native, setNative] = useState("");
	const [isLoadingContracts, setIsLoadingContracts] = useState(false);
	const amountIsNotValid = () =>
		toast.error("Value of buy should be greater than $500");
	const currencySelect = (e) => {
		setCurrency(e.target.value);
	};
	let typingTimer; // Timer identifier
	const doneTypingInterval = 500; // Time in milliseconds (1 second)
	const miniMumBuyAmount = 500;
	useEffect(() => {
		if (chainId === 137) {
			setCurrency("Matic");
		} else if (chainId === 56) {
			setCurrency("BSC");
		}
	}, [chainId]);

	const handleBuy = () => {
		if (currency === "Matic" || currency === "BSC") {
			buyTokenWithNative(native, tokens).finally(() => {
				setNative("");
				setTokens("");
				setIsLoadingContracts(false);
			});
		} else {
			buyTokenWithToken(native, tokens).finally(() => {
				setNative("");
				setTokens("");
				setIsLoadingContracts(false);
			});
		}
	};

	const handleInputChange = (value) => {
		setNative(value);
		if (value === "") {
			setTokens("");
			return;
		}
		if (currency === "Matic" || currency === "BSC") {
			NativeToTokenHelper(value).then((tokens) => {
				let tmpTokens = miniMumBuyAmount / price;
				if (Number(tmpTokens) > Number(tokens)) {
					amountIsNotValid();
					setTokens("");
					return;
				}
				setTokens(Number(tokens));
			});
			return;
		} else {
			if (Number(value) < miniMumBuyAmount) {
				amountIsNotValid();
				setTokens("");
				return;
			}
			let tmpPrice = Number(value) / price;
			setTokens(Number(tmpPrice));
			return;
		}
	};
	function handleInputChangeWhenUserType(event) {
		clearTimeout(typingTimer); // Clear the previous typing timer
		typingTimer = setTimeout(() => {
			handleInputChange(event.target.value); // Call doneTyping function with the input value
		}, doneTypingInterval); // Set a new timer
	}
	const changeProgress = () => {
		var style = document.createElement("style");
		document.head.appendChild(style);
		let width = (tokenSold / tokeninstage) * 100;
		style.sheet.insertRule(
			".box2_text_2::before { width: " + width + "%; }",
			0
		);
	};

	useEffect(() => {
		changeProgress();
	}, [tokenSold]);

	return (
		<>
			<section className="container m-auto p-5 mb-[2.5rem] md:mb-0">
				<section className="flex justify-center items-start lg:flex-row max-sm:flex-col">
					<div className="box2">
						<span className="box2_text">Stage {stage + 1} is Live!</span>
						<span className="box2_text_2">
							<p className="text_box">Until Next Price: <b>${nextPrice}0</b></p>
						</span>
						<div className="leading-[01]">
							{/* <h4>
								USDT Raised: $
								{Math.round((price * tokenSold + Number.EPSILON) * 100) / 100}
							</h4> */}
							{/* <span className="text-xs">Listing Price: $0.80</span> */}
							<span className="box2_text_3_text">Current Price is: <b>${price}0</b></span>
						</div>
						<div className="box2_text_3">
							<span className="box2_text_3_line"></span>
							<span className="box2_text_3_text">Purchase <b>SLT</b> Using BNB, Matic or USDT</span>
							<span className="box2_text_3_line"></span>
						</div>
						<div className="flex justify-around">
							{chainId === 137 ? (
								<button
									className="btn"
									id="Matic"
									value="Matic"
									onClick={currencySelect}
								>
									Matic
								</button>
							) : chainId === 56 ? (
								<button
									className="btn"
									id="bsc"
									value="BSC"
									onClick={currencySelect}
								>
									bsc
								</button>
							) : (
								<div className="btn">Wrong Network</div>
							)}

							<button
								className="btn"
								id="usdt"
								value="USDT"
								onClick={currencySelect}
							>
								usdt
							</button>
							{/* <button className="btn">usd</button> */}
						</div>
						<div className="flex flex-col py-5 px-5 sender rounded-xl">
							<label className="text-left py-2">
								Amount in {currency} You Pay
							</label>
							<input
								placeholder="Amount"
								type="number"
								className="outline-none p-2 rounded-xl"
								onChange={handleInputChangeWhenUserType}
							/>
						</div>
						<div className="flex flex-col py-5 px-5 sender rounded-xl">
							<label className="text-left py-2">
								Amount in <b>SLT</b> You Receive
							</label>
							<input
								type="text"
								className="outline-none p-2 rounded-xl"
								disabled
								value={tokens}
							/>
						</div>
						{isLoadingContracts ? (
							<div className="flex justify-center mt-4">
								<div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
							</div>
						) : (
							<div className="flex w-full">
								{currentAccount ? (
									<button
										className="btn w-full"
										onClick={() => {
											setIsLoadingContracts(true);
											handleBuy();
										}}
									>
										Buy
									</button>
								) : (
									<button className="btn w-full" onClick={connectWallet}>
										connect Wallet
									</button>
								)}
							</div>
						)}
					</div>
				</section>

				<Packages />
			</section>
		</>
	);
}
